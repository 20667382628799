module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KB056TXT1Z"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-owa/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"b67ab8d02600a20f7fc6d93eea5c0c09","owaUrl":"https://lazordfashion.com/owa/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
